$(document).ready(function () {
	'use strict';

	// Hamburger Menu
	let forEach = function (t, o, r) {
		if ("[object Object]" === Object.prototype.toString.call(t)) {
			for (var c in t) {
				Object.prototype.hasOwnProperty.call(t, c) && o.call(r, t[c], c, t);
			}
		} else {
			for (var e = 0, l = t.length; l > e; e++) {
				o.call(r, t[e], e, t)
			}
		}
	};
	let hamburgers = document.querySelectorAll('.hamburger');
	if (hamburgers.length > 0) {
		forEach(hamburgers, function (hamburger) {
			hamburger.addEventListener('click', function () {
				this.classList.toggle('is-active');
			}, false);
		});
	}

	// Global bootstrap
	$('[data-toggle="offcanvas"]').on('click', function () {
		$('.offcanvas-collapse').toggleClass('open')
	});

	// Global popover
	$('[data-toggle="popover"]').popover({
		trigger: 'focus'
	});

	// Auto Textarea Height
	let textAreas = document.querySelectorAll('.autoHeight');
	forEach(textAreas, function (textarea) {
		textarea.addEventListener('input', function () {
			this.style.height = 'auto';
			this.style.height = this.scrollHeight + 'px';
		});
	});

	// Refresh dropdowns. First load page not working (why?)
	$('.dropdown-toggle').dropdown();

	// console.log('[1]');
	// $(document).on('click','.saveResult', function(){
	// 	console.log('[2]');
	// 	// $('.saveResult').click(function(){
	// 	const prevInput = $(this).prev();
	// 	const value = prevInput.val();
	// 	const id = $(this).data('id');
	// 	if(value !== "") {
	// 		console.log('[2.1]');
	// 		$(this).request('onRefreshResult',{
	// 			data:{
	// 				result: value,
	// 				id: id,
	// 			},
	// 			update:{
	// 				'totals':'#totals',
	// 			}
	// 		});
	// 		alert('El valor ha sido registrado.');
	// 	}
	// });
	// console.log('[3]');

});
